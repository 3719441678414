export const TEXT_BAGGAGE_CUSTODY = {
  en_US:
    'Was the luggage under the responsibility of an airline, a maritime transportation company, or a ground transportation company (such as train or bus) at the time of the loss? *',
  es_ES:
    '¿El equipaje estaba bajo la responsabilidad de una compañía aérea, una empresa de transporte marítimo o terrestre (como tren, autobús), en el momento de la pérdida? *',
  pt_BR:
    'A bagagem estava sob a responsabilidade de uma companhia aérea, uma empresa de transporte marítimo ou terrestre (como trem, ônibus) no momento da perda? *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  es_ES: 'Otro (explicar)  *',
  pt_BR: 'Outro (explicar) *',
};

export const OPTIONS_AIRLINE = {
  en_US: 'Airline',
  es_ES: 'Compañía aérea',
  pt_BR: 'Companhia aérea',
};

export const OPTIONS_CRUISE_LINE = {
  en_US: 'Maritime transportation',
  es_ES: 'Transporte marítimo',
  pt_BR: 'Transporte marítimo',
};

export const OPTIONS_RAILROAD = {
  en_US: 'Ground transportation',
  es_ES: 'Transporte terrestre',
  pt_BR: 'Transporte terrestre',
};

export const OPTIONS_BUS = {
  en_US: 'Bus company',
  es_ES: 'Compañía de buses',
  pt_BR: 'Companhia de ônibus',
};

export const OPTIONS_OTHER = {
  en_US: 'Other',
  es_ES: 'Otro',
  pt_BR: 'Outro',
};
