export const DOCUMENTATION_PROVIDED_INFINITE = {
  en_US:
    'If the documentation provided is complete and correct, the amount to be reimbursed will be up to USD 3,000 per insured passenger, which will be paid in local currency. This amount is applicable to Visa Infinite cards.',
  es_ES:
    'Si la documentación proporcionada es completa y correcta, el monto a reembolsar será de hasta USD 3.000 por pasajero asegurado, que se pagará en moneda local. Este monto es aplicable a las tarjetas Visa Infinite.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser reembolsado será de até USD 3.500 para viagens internacionais e até R$ 11.550 para viagens nacionais por passageiro assegurado. Este valor é aplicável aos cartões Visa Infinite.',
};

export const DOCUMENTATION_PROVIDED_SIGNATURE = {
  en_US:
    'If the documentation provided is complete and correct, the amount to be reimbursed will be up to USD 1,000 per insured passenger, which will be paid in local currency. This amount is applicable to Visa Signature cards.',
  es_ES:
    'Si la documentación proporcionada es completa y correcta, el monto a reembolsar será de hasta USD 1.000 por pasajero asegurado, que se pagará en moneda local. Este monto es aplicable a las tarjetas Visa Signature.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser reembolsado será de até USD 3.000 para viagens internacionais e até R$ 9.900 para viagens nacionais por passageiro assegurado. Este valor é aplicável aos cartões Visa Signature.',
};

export const DOCUMENTATION_PROVIDED_PLATINUM = {
  en_US:
    'If the documentation provided is complete and correct, the indemnity amount will be USD $750 for Visa CTA Air 250 / Visa Platinum Corporate.',
  es_ES:
    'Si la documentación proporcionada está completa y correcta, el monto a indemnizar será de USD $750 para Visa CTA Air 250 / Visa Platinum Corporate.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser indenizado será de USD $750 para Visa CTA Air 250 / Visa Platinum Corporate.',
};

export const DOCUMENTATION_PROVIDED_PLATINUM_MEXICO = {
  en_US: '',
  es_ES:
    'Si la documentación proporcionada es completa y correcta, el monto a reembolsar será de hasta USD 800 por pasajero asegurado, que se pagará en moneda local. Este monto es aplicable a las tarjetas Visa Platinum de México.',
  pt_BR: '',
};

export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder *',
  pt_BR: 'Relação com o titular do cartão *',
  es_ES: 'Relación con el titular de la tarjeta *',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const CARDHOLDER_DISCLAIMER = {
  en_US:
    'We remind you that the claim must be initiated by the holder of the card used for the purchase of the ticket(s), that is, the person whose name appears on the Visa card.',
  pt_BR:
    'Lembramos que o sinistro deve ser iniciado pelo titular do cartão utilizado na compra da(s) passagem(s), ou seja, a pessoa cujo nome consta no cartão Visa.',
  es_ES:
    'Recordamos que el reclamo debe ser iniciado por el titular de la tarjeta utilizada para la compra del/los pasaje(s), es decir, la persona cuyo nombre aparece en la tarjeta Visa.',
};
