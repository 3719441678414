import React, { useState, useEffect, useContext } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import { claimsServices } from '../../../../services';
import styles from './RecentClaims.module.scss';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import Loading from '../../../Loading/Loading';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import ListRecentClaims from '../../../ListRecentClaims/ListRecentClaims';
import ViewAllBenefits from '../../../ViewAllBenefits/ViewAllBenefits';
import { CLAIM_SITE_SECTION } from '../../../../utils/GTM_helper';
import TotalReimbursed from '../../../TotalReimbursed/TotalReimbursed';
import { CLAIMS } from '../../../../utils/consts';
import { REACT_APP_TOTAL_REIMBURSEMENT } from '../../../../utils/environments_variables';

const RecentClaims = props => {
  const { benefits } = props;
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const [generatedClaims, setGeneratedClaims] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMonetization, seHasMonetization] = useState(true);
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  useEffect(() => {
    const getGenerated = async () => {
      try {
        const userClaims = await claimsServices.getClaims(
          cn,
          binOrPan,
          idiomForApi(),
          '&max_results=2'
        );
        setGeneratedClaims(userClaims.data);
      } catch (error) {}

      setLoading(false);
    };

    getGenerated();
  }, [binOrPan]);

  useEffect(() => {
    if (document.getElementById('monetization-content')) {
      seHasMonetization(true);
    } else {
      seHasMonetization(false);
    }
  }, [loading]);

  return (
    <>
      <div className={styles.container}>
        <h1 className={`${styles[theme]} ${styles.title}`}>{intl.TITLE}</h1>
        {benefits && benefits.length > 0 && (
          <div>
            {generatedClaims && generatedClaims.length > 0 ? (
              <span className={`${styles[theme]} ${styles.subtitle}`}>
                {intl.SUBTITLE}
              </span>
            ) : (
              <span
                className={`${styles[theme]} ${styles.subtitle} ${styles.noHaveClaim}`}
              >
                {intl.TEXT_NO_HAVE_CLAIM}
              </span>
            )}
          </div>
        )}

        <div className={styles.itens}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Loading surface="Inverse" />
            </div>
          ) : (
            <div>
              {/* commented for refund rules review */}
              {REACT_APP_TOTAL_REIMBURSEMENT && (
                <TotalReimbursed
                  bottom
                  border
                  type={CLAIMS}
                  showArrow
                  shouldShowLinkSaving
                  shouldShowTooltip
                />
              )}
              {generatedClaims && generatedClaims.length > 0 && (
                <h3
                  className={`${
                    hasMonetization
                      ? styles.subTitleRecentClaims
                      : styles.subTitleRecentClaims2
                  } ${styles[theme]}`}
                >
                  {intl.SUBTITLE_YOUR_CLAIMS}
                </h3>
              )}

              <ul>
                <ListRecentClaims
                  claims={generatedClaims}
                  benefits={benefits}
                  numberToFilter={2}
                  siteSection={CLAIM_SITE_SECTION}
                />
              </ul>
              {generatedClaims && generatedClaims.length > 0 && (
                <ViewAllBenefits
                  text={intl.TEXT_ALL_CLAIMS}
                  link="/profile/claims"
                  disablePadding
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RecentClaims;
